// LandingPage.js
import React from 'react';

type FlexDirection = 'row' | 'row-reverse' | 'column' | 'column-reverse';

const LandingPage = () => {
  const imageUrl = window.location.origin + '/landingImage-min.jpeg';

  const containerStyle: React.CSSProperties & { flexDirection: FlexDirection } = {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    backgroundImage: `url(${imageUrl})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  const overlayStyle: React.CSSProperties = {
    content: '',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.3)', // Semi-transparent black background color
  };

  const textContainerStyle: React.CSSProperties = {
    position: 'relative',
    zIndex: 1,
  };

  const headingStyle = {
    fontSize: '36px',
    fontWeight: 'bold',
    marginBottom: '10px',
    color: '#ffffff',
  };

  const descriptionStyle: React.CSSProperties = {
    fontSize: '18px',
    maxWidth: '600px',
    textAlign: 'center',
    color: '#ffffff',
  };

  return (
    <div style={containerStyle}>
      <div style={overlayStyle} />
      <div style={textContainerStyle}>
        <h1 style={headingStyle}>Welcome to Porlock Holidays</h1>
        <p style={descriptionStyle}>
          We're still building our Porlock Holidays, please come back soon.
        </p>
      </div>
    </div>
  );
};

export default LandingPage;
